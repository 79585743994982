import Heading from '@/components/Heading.vue';
import LayoutTwoColumns from '@/components/layouts/LayoutTwoColumns.vue';
import ResponsiveImage from '@/components/ResponsiveImage.vue';
import ToolbarBase from '@/components/ToolbarBase.vue';
import AutoIdent from '@/pages/profile/signature-standards/zertes/auto.vue';
export default defineComponent({
  components: {
    LayoutTwoColumns: LayoutTwoColumns,
    ToolbarBase: ToolbarBase,
    ResponsiveImage: ResponsiveImage,
    Heading: Heading
  },
  extends: AutoIdent,
  provide: function provide() {
    return {
      headingBottomPaddingClass: 'pb-3'
    };
  },
  matomo: false,
  created: function created() {
    var _this$$matomo, _this$$matomo2;
    (_this$$matomo = this.$matomo) === null || _this$$matomo === void 0 ? void 0 : _this$$matomo.setDocumentTitle('NAS Ident - Auto identification');
    (_this$$matomo2 = this.$matomo) === null || _this$$matomo2 === void 0 ? void 0 : _this$$matomo2.trackPageView();
  }
});